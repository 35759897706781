/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAgfWa5cb37LTQzPLwNvLxhP_Cb7FWVqCI",
  "appId": "1:1084125579307:web:6fb7599f4b69468142c26e",
  "authDomain": "schooldog-i-csdecatur-ga.firebaseapp.com",
  "measurementId": "G-CMSBK8Z28D",
  "messagingSenderId": "1084125579307",
  "project": "schooldog-i-csdecatur-ga",
  "projectId": "schooldog-i-csdecatur-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-csdecatur-ga.appspot.com"
}
